import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/Layout.jsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const TipsHeading = makeShortcode("TipsHeading");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <TipsHeading frontmatter={props.pageContext.frontmatter} mdxType="TipsHeading" />
    <p>{`Did you know that Liquid supports multiline assignment? This isn't really a Mechanic-specific tip, but it's a useful one when you are building complex task scripts that have a large number of similar assignments. Admittedly, this is completely a personal preference.`}</p>
    <p>{`For example:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "liquid"
    }}><pre parentName="div" {...{
        "className": "language-liquid"
      }}><code parentName="pre" {...{
          "className": "language-liquid"
        }}><span parentName="code" {...{
            "className": "token liquid language-liquid"
          }}><span parentName="span" {...{
              "className": "token delimiter punctuation"
            }}>{`{%`}</span>{` `}<span parentName="span" {...{
              "className": "token keyword"
            }}>{`assign`}</span>{` anvils `}<span parentName="span" {...{
              "className": "token operator"
            }}>{`=`}</span>{` result`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`.`}</span>{`data`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`.`}</span>{`products`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`.`}</span>{`edges `}<span parentName="span" {...{
              "className": "token operator"
            }}>{`|`}</span>{` `}<span parentName="span" {...{
              "className": "token function filter"
            }}>{`map`}</span><span parentName="span" {...{
              "className": "token operator"
            }}>{`:`}</span>{` `}<span parentName="span" {...{
              "className": "token string"
            }}>{`"node"`}</span>{` `}<span parentName="span" {...{
              "className": "token operator"
            }}>{`|`}</span>{` `}<span parentName="span" {...{
              "className": "token function filter"
            }}>{`where`}</span><span parentName="span" {...{
              "className": "token operator"
            }}>{`:`}</span>{` `}<span parentName="span" {...{
              "className": "token string"
            }}>{`"productType"`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{` `}<span parentName="span" {...{
              "className": "token string"
            }}>{`"Anvil"`}</span>{` `}<span parentName="span" {...{
              "className": "token delimiter punctuation"
            }}>{`%}`}</span></span>{`
`}<span parentName="code" {...{
            "className": "token liquid language-liquid"
          }}><span parentName="span" {...{
              "className": "token delimiter punctuation"
            }}>{`{%`}</span>{` `}<span parentName="span" {...{
              "className": "token keyword"
            }}>{`assign`}</span>{` bricks `}<span parentName="span" {...{
              "className": "token operator"
            }}>{`=`}</span>{` result`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`.`}</span>{`data`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`.`}</span>{`products`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`.`}</span>{`edges `}<span parentName="span" {...{
              "className": "token operator"
            }}>{`|`}</span>{` `}<span parentName="span" {...{
              "className": "token function filter"
            }}>{`map`}</span><span parentName="span" {...{
              "className": "token operator"
            }}>{`:`}</span>{` `}<span parentName="span" {...{
              "className": "token string"
            }}>{`"node"`}</span>{` `}<span parentName="span" {...{
              "className": "token operator"
            }}>{`|`}</span>{` `}<span parentName="span" {...{
              "className": "token function filter"
            }}>{`where`}</span><span parentName="span" {...{
              "className": "token operator"
            }}>{`:`}</span>{` `}<span parentName="span" {...{
              "className": "token string"
            }}>{`"productType"`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{` `}<span parentName="span" {...{
              "className": "token string"
            }}>{`"Brick"`}</span>{` `}<span parentName="span" {...{
              "className": "token delimiter punctuation"
            }}>{`%}`}</span></span></code></pre></div>
    <p>{`For example:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "liquid"
    }}><pre parentName="div" {...{
        "style": {
          "counterReset": "linenumber 0"
        },
        "className": "language-liquid line-numbers"
      }}><code parentName="pre" {...{
          "className": "language-liquid"
        }}><span parentName="code" {...{
            "className": "token liquid language-liquid"
          }}><span parentName="span" {...{
              "className": "token delimiter punctuation"
            }}>{`{%`}</span>{` `}<span parentName="span" {...{
              "className": "token keyword"
            }}>{`assign`}</span>{` anvils
  `}<span parentName="span" {...{
              "className": "token operator"
            }}>{`=`}</span>{` result`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`.`}</span>{`data`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`.`}</span>{`products`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`.`}</span>{`edges
  `}<span parentName="span" {...{
              "className": "token operator"
            }}>{`|`}</span>{` `}<span parentName="span" {...{
              "className": "token function filter"
            }}>{`map`}</span><span parentName="span" {...{
              "className": "token operator"
            }}>{`:`}</span>{` `}<span parentName="span" {...{
              "className": "token string"
            }}>{`"node"`}</span>{`
  `}<span parentName="span" {...{
              "className": "token operator"
            }}>{`|`}</span>{` `}<span parentName="span" {...{
              "className": "token function filter"
            }}>{`where`}</span><span parentName="span" {...{
              "className": "token operator"
            }}>{`:`}</span>{` `}<span parentName="span" {...{
              "className": "token string"
            }}>{`"productType"`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{` `}<span parentName="span" {...{
              "className": "token string"
            }}>{`"Anvil"`}</span>{`
`}<span parentName="span" {...{
              "className": "token delimiter punctuation"
            }}>{`%}`}</span></span>{`
`}<span parentName="code" {...{
            "className": "token liquid language-liquid"
          }}><span parentName="span" {...{
              "className": "token delimiter punctuation"
            }}>{`{%`}</span>{` `}<span parentName="span" {...{
              "className": "token keyword"
            }}>{`assign`}</span>{` bricks
  `}<span parentName="span" {...{
              "className": "token operator"
            }}>{`=`}</span>{` result`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`.`}</span>{`data`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`.`}</span>{`products`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`.`}</span>{`edges
  `}<span parentName="span" {...{
              "className": "token operator"
            }}>{`|`}</span>{` `}<span parentName="span" {...{
              "className": "token function filter"
            }}>{`map`}</span><span parentName="span" {...{
              "className": "token operator"
            }}>{`:`}</span>{` `}<span parentName="span" {...{
              "className": "token string"
            }}>{`"node"`}</span>{`
  `}<span parentName="span" {...{
              "className": "token operator"
            }}>{`|`}</span>{` `}<span parentName="span" {...{
              "className": "token function filter"
            }}>{`where`}</span><span parentName="span" {...{
              "className": "token operator"
            }}>{`:`}</span>{` `}<span parentName="span" {...{
              "className": "token string"
            }}>{`"productType"`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{` `}<span parentName="span" {...{
              "className": "token string"
            }}>{`"Brick"`}</span>{`
`}<span parentName="span" {...{
              "className": "token delimiter punctuation"
            }}>{`%}`}</span></span></code><span parentName="pre" {...{
          "aria-hidden": "true",
          "className": "line-numbers-rows",
          "style": {
            "whiteSpace": "normal",
            "width": "auto",
            "left": "0"
          }
        }}><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span></span></pre></div>
    <p>{`lorem ipsum`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      